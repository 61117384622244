import React, { useRef } from "react";
import ImageDetails from "../Components/UnitDetails/ImageDetails";
import SellerDetails from "../Components/UnitDetails/SellerDetails";
import PrivatePerson from "../Components/UnitDetails/PrivatePerson";
import Phone from "../Components/UnitDetails/Phone";
import SendMessageBtn from "../Components/UnitDetails/SendMessageBtn";
///import RecommendedUnitCard from "../Components/UnitDetails/RecommendedUnitCard";
import UnitCardAll from "../Components/AllUnits/UnitCardAll";
import UnitSale from "../Components/UnitDetails/UnitSale";
import UnitDescription from "../Components/UnitDetails/UnitDescription";
///import UnitLocation from "../Components/UnitDetails/UnitLocation";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { getone, getlatest } from "../api/unit";
import Spinner from "../Components/Spinner";
import { useCallback } from "react";
import { proxy } from "../config";

export default function UnitDetails(props) {
  const [recommendedUnits, setrecommendedunits] = useState([]);
  const params = useParams();

  const [unit, setUnit] = useState();
  const [name, setname] = useState("");
  useEffect(() => {
    getone(params.id).then((result) => {
      setUnit(result.data.response);
      setname(result.data.name);
      window.scrollTo(0, 0);
    });
    getlatest().then((result) => {
      setrecommendedunits(result.data.response);
    });
  }, [params.id]);

  const mapComponent = useRef(null);

  const onMapPress = useCallback(() => {
    //  mapComponent.current?.scrollIntoView({ behavior: "smooth" });
  }, [mapComponent]);

  if (!unit) return <Spinner />;
  return (
    <div className="pageWidth">
      <div className="row mt-3">
        <div className="col-lg-8 col-md-12 mb-2">
          <ImageDetails
            adname={unit.adname}
            price={unit?.price?.value}
            size={unit.size}
            images={unit.images}
            onMapPress={onMapPress}
          />
        </div>

        <div className="col-lg-4 col-md-12">
          <div className="detailsContainer">
            <SellerDetails id={unit._id} user_id={unit.user_id} phone={unit.phone}whatsapp={unit.whatsapp} name={name} update_favorites={props.update_favorites} favorites={props.favorites} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-md-12">
          <UnitSale
            Size={unit.size + " ㎡"}
            Rooms={unit.rooms}
            Bathrooms={unit.bathrooms}
            Furnished={unit.furnished ? "Yes" : "No"}
            Price={
              unit?.price?.value?.toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + " E.G.P" || ""
            }
            location={"Cairo"}
            Floor_level={unit.floor_level}
            MoveInDate={unit.moveInDate}
            Payment={unit.payment}
            Upfront={unit.upfront}
            TypeofHouse={unit.type2}
            finished={unit?.finished}
            unitCode={unit?.unitCode}
            Owner={unit.owner?"Yes":"No"}
          />

          <UnitDescription description={unit.description} />

          {/* <div className="detailsContainer my-3" ref={mapComponent}>
            <div className="m-4">
              <UnitLocation />
            </div>
          </div> */}

          <div className="detailsContainer my-3">
            <div className="m-4">
              <h5 className="text-dark fw-bold text-center my-4">
                Contact the advertiser
              </h5>
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <PrivatePerson name={name} />
                </div>
                <div className="offset-lg-2 col-lg-6 col-md-12">
                  <Phone phone={unit.phone} />
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row detailsContainer m-1 mb-4">
        <div className="m-2">
          <h5 className="text-dark fw-bold my-3 mx-4" style={{textAlign:'start'}}>
            You may also be interested in this
          </h5>
          <div className="row mx-0 mx-lg-2 ">
            {recommendedUnits.map((item, index) => (
              <div className="col-lg-4 col-md-4 col-sm-12 my-2">
                {/* <RecommendedUnitCard /> */}
                <UnitCardAll
                  destination={`/details/${item._id}`}
                  adname={item?.title}
                  size={item?.size}
                  price={item?.price?.value}
                  imagee={proxy + "/" + item.images[0]}
                  description={item?.description}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
