import React from "react";

export default function Footer() {
  return (
    <footer className="text-center text-lg-start bg-dark text-muted p-1">
      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="fw-bold mb-4 text-danger">For Companies</h6>
              <h6 className="text-uppercase fw-bold mb-4 text-light">Posting Ad </h6>
              <h6 className="text-uppercase fw-bold mb-4 text-light">Reach to all users</h6>
              <h6 className="text-uppercase fw-bold mb-4 text-light">Grow your Business now</h6>
              


            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="fw-bold mb-5"></h6>
              
            </div>

            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
              <h6 className="fw-bold mb-4 text-danger">For Persons</h6>
              <h6 className="text-uppercase fw-bold mb-4 text-light">
                Access For All Units
              </h6>
              <h6 className="text-uppercase fw-bold mb-4 text-light">Chat With all owners</h6>
              <h6 className="text-uppercase fw-bold mb-4 text-light">Get Your Optimal Unit</h6>
            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="fw-bold mb-4 text-danger">About us</h6>
              <h6 className="text-uppercase fw-bold mb-4 text-light">
                aqar@info
              </h6>
            </div>

            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
              <h6 className="fw-bold mb-4 text-danger">Contact us</h6>
              <h6 className="fw-bold text-light">+2011223344556</h6>
              <h6 className="fw-bold text-light">+2011223344556</h6>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}