import React from "react";

import AdminCard from "../../Components/Dashboard/AdminCard";
import { getall, gethomeids, adminquery } from "../../api/unit";
import { useEffect } from "react";
import { useState } from "react";
import { proxy } from "../../config";
import SearchBar from "../../Components/Dashboard/SearchBar";

export default function ManageUnits() {
  const [units, setUnits] = useState([]);
  const [ids, setids] = useState([]);
  const updateids = async () => {
    gethomeids().then((result) => setids(result.data.response));
  };
  useEffect(() => {
    // window.location.reload();
    getall().then((result) => setUnits(result.data.response));
    updateids();
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onSearchPress = () => {
    if (searchValue.length > 4) {
      adminquery(searchValue).then((result) => {
        console.log(result.data);
        setUnits(result.data.response);
      });
    }
  };

  return (
    <div className="p-3">
      <div className="w-75 m-auto">
        <SearchBar
          value={searchValue}
          onChange={handleSearchChange}
          onSearch={onSearchPress}
        />
      </div>
      <div className="row my-4">
        {units.map((item, index) => (
          <div key={item._id} className="col-lg-3 col-md-6 col-sm-12 my-2 ">
            <AdminCard
              id={item._id}
              destination={`/details/${item._id}`}
              adname={item.adname}
              size={item.size}
              price={item?.price?.value}
              imagee={proxy + "/" + item.images[0]}
              description={item.description}
              home={ids}
              updateids={updateids}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
