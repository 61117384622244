import React, { useEffect, useState } from "react";

import { getall } from "../api/unit";
import ShowUnits from "../Components/ShowUnits";
import { useTranslation } from "react-i18next";

export default function AllUnits(props) {
  const [units, setUnits] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    getall().then((result) => setUnits(result.data.response));
  }, []);
  return <ShowUnits title={t('all_units')} units={units} update_favorites={props.update_favorites} favorites={props.favorites} />;
}
