import React from "react";
import { useLocation } from "react-router-dom";

import ShowUnits from "../Components/ShowUnits";

export default function FilteredUnits(props) {
  const { state } = useLocation();
  if (state?.units)
    return (
      <ShowUnits title={"Search Results"} units={state?.units} update_favorites={props.update_favorites} favorites={props.favorites} />
    );
  else return <ShowUnits title={"Empty Query"} units={[]} update_favorites={props.update_favorites} favorites={props.favorites} />;
}
