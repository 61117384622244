import './jobselected.css';
import Jobdetails from "./JobDetails";
import JobCard from './JobCard';
import { useEffect, useState } from 'react';
import ApplyForm from './ApplyForm';
import * as Ads from "../../network/api/ad"
import * as Applicant from "../../network/api/applicant"
import * as Freelancer from "../../network/api/freelance"
import { useParams } from 'react-router-dom';


function JobSelected() {
  const [apply, setApply] = useState(false);

  let { id } = useParams()

  const query = window.location.pathname.split("/")[2];

  const [allAds, setAllAds] = useState([])
  const [companies, setCompanies] = useState([])
  const [ad, setAd] = useState({})
  const [company, setCompany] = useState({})
  const [numberOfApplicant, setNumberOfApplicant] = useState(0)
  const [emails, setEmails] = useState([])
  const [profile, setProfile] = useState({})

  const getAllAds = async () => {
    await Ads.getAllAds().then(async (result) => {
      setAllAds(result.data.response)
      setCompanies(result.data.companies)
    })
  }

  const getAd = async () => {
    await Ads.getAd(id).then(async (result) => {
      setAd(result.data.response)
      setCompany(result.data.company)
    })
  }

  const getProfile = async () => {
    await Freelancer.getProfile().then(async (result) => {
      setProfile(result.data.response)
    })
  }

  const getNumberOfApplicants = async () => {
    await Applicant.getNumberOfApplicants(id).then(async (result) => {
      setNumberOfApplicant(result.data.response.length)
      setEmails(result.data.response)
    })
  }

  useEffect(() => {
    getAllAds()
    getAd()
    getProfile()
    getNumberOfApplicants()
  }, [id])
  
  return (
    <div className="job-selected">
      <div className="container d-flex justify-content-between" style={{gap:"1rem"}}>
        <div className="related-jobs px-1" style={{ width: '45%' }}>
          {allAds.map((ad, index) => (
            <JobCard key={index} ad={ad} company={companies[index]} isHome={query === "jobselected"} />
          ))}
        </div>
        <Jobdetails setApply={setApply} ad={ad} company={company} numberOfApplicant={numberOfApplicant} isApply={emails?.includes(profile?.email)} profile={profile} />
      </div>
      {apply && <ApplyForm setApply={setApply} ad={ad} profile={profile} />}
    </div>
  )
}

export default JobSelected;
