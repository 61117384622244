import React from "react";

export default function UnitDescription({ description }) {
  return (
    <div className="detailsContainer my-3">
      <div className="m-4">
        <h4 className="text-dark fw-bold my-3 text-center">Description</h4>
        <p className="text-center">{description}</p>
      </div>
    </div>
  );
}
